/* eslint-disable no-restricted-syntax */
export default (axios) => (resource) => ({
  list(extension = null) {
    if (extension) {
      return axios.get(`/${resource}/${extension}`);
    }
    return axios.get(`/${resource}`);
  },

  query(paramsObj, extension = null) {
    let output = '?';

    for (const [key, value] of Object.entries(paramsObj)) {
      output += `${key}=${value}&`;
    }
    output = output.slice(0, -1);

    if (extension) {
      return axios.get(`/${resource}/${extension}${output}`);
    }
    return axios.get(`/${resource}${output}`);
  },

  retrieve(id, extension = null) {
    if (extension) {
      return axios.get(`/${resource}/${extension}/${id}`);
    }
    return axios.get(`/${resource}/${id}`);
  },

  create(payload, extension = null) {
    if (extension) {
      return axios.post(`/${resource}/${extension}`, payload);
    }
    return axios.post(`/${resource}/`, payload);
  },

  lazyCreate(extension = null) {
    return axios.post(`/${resource}/${extension}`);
  },

  update(id, payload, extension = null) {
    if (extension) {
      return axios.put(`/${resource}/${extension}/${id}`, payload);
    }
    return axios.put(`/${resource}/${id}`, payload);
  },

  lazyUpdate(payload, extension = null) {
    return axios.put(`/${resource}/${extension}`, payload);
  },

  partialUpdate(id, payload, extension = null) {
    if (extension) {
      return axios.patch(`/${resource}/${id}/${extension}`, payload);
    }
    return axios.patch(`/${resource}/${id}`, payload);
  },

  lazyPartialUpdate(payload, extension = null) {
    if (extension) {
      return axios.patch(`/${resource}/${extension}`, payload);
    }
    return axios.patch(`/${resource}`, payload);
  },

  delete(id, extension = null) {
    return axios.delete(`/${resource}/${extension}/${id}`);
  },

  deleteParam(paramsObj, extension = null) {
    let output = '?';

    for (const [key, value] of Object.entries(paramsObj)) {
      output += `${key}=${value}&`;
    }
    output = output.slice(0, -1);

    if (extension) {
      return axios.delete(`/${resource}/${extension}${output}`);
    }
    return axios.delete(`/${resource}${output}`);
  },

  lazyDelete(extension = null) {
    return axios.delete(`/${resource}/${extension}`);
  },
});
