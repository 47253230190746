import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthGuard } from 'src/auth/guard';
import CompactLayout from 'src/layouts/compact';
import DashboardLayout from 'src/layouts/dashboard';

import { SplashScreen, LoadingScreen } from 'src/components/loading-screen';
// import ForumKanbanPage from 'src/pages/dashboard/forum/forum-kanban';

// * ---------------------------------------------------------------------- //

const IndexPage = lazy(() => import('src/pages/dashboard/landing'));

//* Analytics
const OverviewAnalyticsPage = lazy(() => import('src/pages/dashboard/analytics/overview'));
const UserTaskCompletionPage = lazy(
  () => import('src/pages/dashboard/analytics/user-task-completion')
);
const UserTargetCompletionPage = lazy(
  () => import('src/pages/dashboard/analytics/user-target-completion')
);

// * User
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));

//* Role
const RoleListPage = lazy(() => import('src/pages/dashboard/role/list'));
const RoleCreatePage = lazy(() => import('src/pages/dashboard/role/new'));
const RoleEditPage = lazy(() => import('src/pages/dashboard/role/edit'));

//* Project
const ProjectListPage = lazy(() => import('src/pages/dashboard/project/list'));
const ProjectCreatePage = lazy(() => import('src/pages/dashboard/project/new'));
const ProjectEditPage = lazy(() => import('src/pages/dashboard/project/edit'));
const ProjectDetailsPage = lazy(() => import('src/pages/dashboard/project/details'));

// Blog
const BlogPostsPage = lazy(() => import('src/pages/dashboard/post/list'));
const PostDetailsPage = lazy(() => import('src/pages/dashboard/post/details'));
const PostCreatePage = lazy(() => import('src/pages/dashboard/post/new'));
const PostEditPage = lazy(() => import('src/pages/dashboard/post/edit'));

const TargetCreatePage = lazy(() => import('src/pages/dashboard/project/target/new'));
const TargetEditPage = lazy(() => import('src/pages/dashboard/project/target/edit'));
const TargetDetailsPage = lazy(() => import('src/pages/dashboard/project/target/details'));

//* Forum
const TasksKanbanPage = lazy(() => import('src/pages/dashboard/forum/task/task-kanban'));
const TaskCreatePage = lazy(() => import('src/pages/dashboard/forum/task/new'));

const Page500 = lazy(() => import('src/pages/500'));
const Page403 = lazy(() => import('src/pages/403'));
const Page404 = lazy(() => import('src/pages/404'));

// * ---------------------------------------------------------------------- //

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      {
        path: 'analytics',
        children: [
          { element: <OverviewAnalyticsPage />, index: true },
          { path: 'overview', element: <OverviewAnalyticsPage /> },
          { path: 'task-completion', element: <UserTaskCompletionPage /> },
          { path: 'target-completion', element: <UserTargetCompletionPage /> },
        ],
      },
      {
        path: 'posts',
        children: [
          { element: <BlogPostsPage />, index: true },
          { path: 'list', element: <BlogPostsPage /> },
          { path: ':id', element: <PostDetailsPage /> },
          { path: ':id/edit', element: <PostEditPage /> },
          { path: 'new', element: <PostCreatePage /> },
        ],
      },
      {
        path: 'users',
        children: [
          { element: <UserListPage />, index: true },
          // { path: 'new', element: <UserCreatePage /> },
          // { path: ':id/edit', element: <UserEditPage /> },
          // { path: 'account', element: <UserAccountPage /> },
        ],
      },
      {
        path: 'roles',
        children: [
          { element: <RoleListPage />, index: true },
          { path: 'new', element: <RoleCreatePage /> },
          { path: ':id/edit', element: <RoleEditPage /> },
        ],
      },
      {
        path: 'projects',
        children: [
          { element: <ProjectListPage />, index: true },
          { path: 'new', element: <ProjectCreatePage /> },
          { path: ':id/edit', element: <ProjectEditPage /> },
          { path: ':id', element: <ProjectDetailsPage /> },
          {
            path: ':projectId/targets',
            children: [
              { path: 'new', element: <TargetCreatePage /> },
              { path: ':id/edit', element: <TargetEditPage /> },
              { path: ':id', element: <TargetDetailsPage /> },
            ],
          },
        ],
      },
      {
        path: 'forum',
        children: [
          { path: 'tasks', element: <TasksKanbanPage /> },
          { path: 'tasks/new', element: <TaskCreatePage /> },
        ],
      },

      {
        element: (
          <CompactLayout>
            <Suspense fallback={<SplashScreen />}>
              <Outlet />
            </Suspense>
          </CompactLayout>
        ),
        children: [
          // { path: 'coming-soon', element: <ComingSoonPage /> },
          // { path: 'maintenance', element: <MaintenancePage /> },
          { path: '500', element: <Page500 /> },
          { path: '404', element: <Page404 /> },
          { path: '403', element: <Page403 /> },
        ],
      },
    ],
  },
];
