import axios from 'axios';
import { enqueueSnackbar } from 'notistack';

import { PaginationLimit } from 'src/constants';
// eslint-disable-next-line import/no-cycle
import { HOST_API, HOST_API_PREFIX } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API + HOST_API_PREFIX });

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;

    if (response?.status === 401) {
      // refreshWithoutArg();

      enqueueSnackbar('Unauthorized', { variant: 'error' });
    }

    if (response?.status === 403) {
      enqueueSnackbar('Forbidden', { variant: 'error' });
    }

    if (response?.status === 404) {
      enqueueSnackbar('Not Found', { variant: 'error' });
    }

    if (response?.status === 500) {
      enqueueSnackbar('Internal Server Error', { variant: 'error' });
    }

    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  auth: {
    login: '/auth/login',
    register: '/auth/register',
    refresh: '/auth/refresh-token',
  },
  lookups: {
    list: `/data-lookups/?limit=${PaginationLimit.DATALOOKUPS}`,
  },
  users: {
    list: `/users/?limit=${PaginationLimit.USERS}`,
    single: (userId) => `/users/${userId}`,
    me: '/users/me',
  },
  roles: {
    list: `/roles/?limit=${PaginationLimit.ROLES}`,
    single: (roleId) => `/roles/${roleId}`,
    permissions: (roleId) => `/roles/${roleId}/permissions`,
  },
  permissions: {
    list: `/permissions/?limit=${PaginationLimit.PERMISSIONS}`,
  },
  projects: {
    list: (page, sort, limit) =>
      `/projects/?page=${page}/&sortBy=createdAt&sortOrder=${sort}&limit=${limit}`,
    single: (projectId) => `/projects/${projectId}`,
    targets: {
      list: (projectId) => `targets/?project=${projectId}`,
      listAll: (page, limit) => `/targets?page=${page}&limit=${limit}`,
      single: (targetId) => `targets/${targetId}`,
      analytics: {
        insights: '/targets/insights',
        completers: (period) => `targets/top-completers?type=${period}`,
      },
    },
  },
  tasks: {
    list: (startDate, endDate) =>
      `/tasks/?limit=${PaginationLimit.TASKS}&sortBy=createdAt&sortOrder=ASC&startDate=${startDate}&endDate=${endDate}`,
    // fiterByDate: `/tasks/?limit=${PaginationLimit.TASKS}&sortBy=createdAt&sortOrder=ASC&startDate=1&endDate=1`
    single: (taskId) => `/tasks/${taskId}`,
    analytics: {
      insights: (period) => `/tasks/insights?type=${period}`,
      completers: (period) => `tasks/top-completers?type=${period}`,
      countPerProject: '/tasks/task-per-project',
    },
  },
  posts: {
    list: (page, sort, limit) =>
      `/blogs/?page=${page}/&sortBy=createdAt&sortOrder=${sort}&limit=${limit}`,
    // `/blogs/?page=${page}/&sortBy=createdAt&sortOrder=${sort}&limit=${limit}`,

    details: (id) => `/blogs/${id}`,
    latest: 'https://api-dev-minimal-v510.vercel.app/api/post/latest',
    search: 'https://api-dev-minimal-v510.vercel.app/api/post/search',
  },
  kanban: 'https://api-dev-minimal-v510.vercel.app/api/kanban',
};
