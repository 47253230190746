import { useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { usePermitted } from 'src/permissions/acl';

// ----------------------------------------------------------------------

export function useRouter() {
  const navigate = useNavigate();
  const { checkPermission } = usePermitted();

  const navigateWithPermission = useCallback(
    (options) => {
      const { href, delta, back, forward, push, replace, reload } = options;

      const permitted = checkPermission(href);

      if (back) {
        if (permitted) navigate(-delta);
      }
      if (forward) {
        if (permitted) navigate(delta);
      }
      if (push) {
        if (permitted) navigate(href);
      }
      if (replace) {
        if (permitted) navigate(href, { replace: true });
      }
      if (reload) {
        if (permitted) navigate();
      }
    },
    [navigate, checkPermission]
  );

  const router = useMemo(
    () => ({
      back: () =>
        navigateWithPermission({
          back: true,
          href: '',
        }),
      forward: () =>
        navigateWithPermission({
          forward: true,
          href: '',
        }),
      reload: () =>
        navigateWithPermission({
          reload: true,
          href: '',
        }),
      push: (href) =>
        navigateWithPermission({
          push: true,
          href,
        }),
      replace: (href) =>
        href.includes('login')
          ? navigate(href, { replace: true })
          : navigateWithPermission({
              href,
              replace: true,
            }),
    }),
    [navigateWithPermission, navigate]
  );

  return router;
}
