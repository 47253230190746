import PropTypes from 'prop-types';
import { useMemo, useEffect, useReducer, useContext, useCallback } from 'react';

import { fDate } from 'src/utils/format-time';
import axios, { endpoints } from 'src/utils/axios';

import { UserContext } from 'src/user/context';
import { PermissionsContext } from 'src/permissions/context';

import { AuthContext } from './auth-context';
import { setSession, isValidToken } from './utils';

// ----------------------------------------------------------------------
/**
 * NOTE:
 * We only build demo at basic level.
 * Customer will need to do some extra handling yourself if you want to extend the logic and other features...
 */
// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'REFRESH') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const ACCESS_STORAGE_KEY = 'accessToken';
const REFRESH_STORAGE_KEY = 'refreshToken';

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { setUser } = useContext(UserContext);
  const { setRoles } = useContext(PermissionsContext);

  const initialize = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem(ACCESS_STORAGE_KEY);
      const refreshToken = sessionStorage.getItem(REFRESH_STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken, refreshToken);

        const response = await axios.get(endpoints.users.me);

        const user = response.data.data;

        setUser(user);
        setRoles(user.roles);

        sessionStorage.setItem('user', JSON.stringify(user));

        dispatch({
          type: 'INITIAL',
          payload: {
            user: {
              ...user,
              accessToken,
            },
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, [setUser, setRoles]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(
    async (email, password) => {
      const data = {
        email,
        password,
      };

      const response = await axios.post(endpoints.auth.login, data);

      const { accessToken, refreshToken } = response.data.data;

      setSession(accessToken, refreshToken);

      const responseUser = await axios.get(endpoints.users.me);

      const user = responseUser.data.data;

      setUser(user);
      setRoles(user.roles);

      dispatch({
        type: 'LOGIN',
        payload: {
          user: {
            ...user,
            accessToken,
            refreshToken,
          },
        },
      });
    },
    [setUser, setRoles]
  );

  // REGISTER
  const register = useCallback(
    async (email, password, firstName, lastName, phone, birthDate) => {
      const data = {
        email,
        password,
        phone,
        birthDate: fDate(birthDate, 'yyyy-MM-dd'),
        name: `${firstName} ${lastName}`,
      };

      const response = await axios.post(endpoints.auth.register, data);

      const { accessToken, refreshToken } = response.data.data;

      setSession(accessToken, refreshToken);

      const responseUser = await axios.get(endpoints.users.me);

      const user = responseUser.data.data;

      setUser(user);
      setRoles(user.roles);

      dispatch({
        type: 'REGISTER',
        payload: {
          user: {
            ...user,
            accessToken,
            refreshToken,
          },
        },
      });
    },
    [setUser, setRoles]
  );

  // REFRESH

  const refresh = useCallback(
    async (staleRefreshToken) => {
      const data = {
        refreshToken: staleRefreshToken,
      };

      const response = await axios.post(endpoints.auth.refresh, data);

      const { accessToken, refreshToken } = response.data.data;

      setSession(accessToken, refreshToken);

      const responseUser = await axios.get(endpoints.users.me);

      const user = responseUser.data.data;

      setUser(user);
      setRoles(user.roles);

      dispatch({
        type: 'REFRESH',
        payload: {
          user: {
            ...user,
            accessToken,
            refreshToken,
          },
        },
      });
    },
    [setUser, setRoles]
  );

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // const tokenExpired = useCallback(
  //   (exp) => {
  //     // eslint-disable-next-line prefer-const
  //     let expiredTimer;

  //     const currentTime = Date.now();

  //     // Test token expires after 10s
  //     // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  //     const timeLeft = exp * 100000000 - currentTime;

  //     clearTimeout(expiredTimer);

  //     expiredTimer = setTimeout(() => {
  //       sessionStorage.removeItem('accessToken');
  //       refresh();

  //       window.location.href = paths.auth.jwt.login;
  //     }, timeLeft);
  //   },
  //   [refresh]
  // );

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      register,
      refresh,
      logout,
    }),
    [login, logout, register, refresh, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
