import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  //   const theme = useTheme();

  //   const PRIMARY_LIGHT = theme.palette.primary.light;

  //   const PRIMARY_MAIN = theme.palette.primary.main;

  //   const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="../../assets/icons/icon.png"
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 40,
        height: 40,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="100%"
        viewBox="0 0 880 954"
        // enable-background="new 0 0 880 954"
        // xml:space="preserve"
      >
        <path
          fill="#097454"
          opacity="1.000000"
          stroke="none"
          d="
M1.000000,456.000000 
	C1.000000,442.645782 1.000000,429.291534 1.389670,415.293671 
	C1.519560,411.100006 1.259780,407.550018 1.000000,404.000000 
	C1.000000,403.555542 1.000000,403.111115 1.385709,402.199219 
	C2.180812,400.549072 2.812233,399.394379 2.965620,398.179382 
	C4.703858,384.410645 5.641846,370.494965 8.218414,356.888367 
	C12.428150,334.657135 18.283173,312.809937 25.931767,291.442688 
	C35.123230,265.765259 46.399963,241.063202 60.211922,217.600250 
	C75.699738,191.290451 93.627853,166.598099 114.483208,144.315613 
	C128.163284,129.699432 142.748047,115.784325 158.015335,102.837936 
	C175.339478,88.147369 194.059845,75.212479 213.685471,63.568985 
	C238.422485,48.893005 264.190979,36.596912 291.255798,26.932358 
	C314.924744,18.480427 338.988800,11.439584 363.929260,8.051476 
	C382.067139,5.587484 400.323517,3.997322 418.522125,1.973550 
	C419.702911,1.842238 420.841675,1.333289 422.000000,1.000003 
	C434.354218,1.000000 446.708466,1.000000 459.717468,1.396977 
	C472.048615,2.534276 483.844269,2.477213 495.373596,4.201035 
	C512.845886,6.813420 530.210510,10.308265 547.480408,14.072901 
	C572.978027,19.631094 597.651001,28.019339 621.191711,39.195316 
	C639.898071,48.076157 658.362427,57.652637 676.128662,68.268364 
	C690.027954,76.573486 703.094177,86.439850 715.795593,96.537125 
	C729.285400,107.261215 742.155457,118.808746 754.810425,130.521530 
	C761.556458,136.765289 767.588318,143.864716 773.391968,151.024307 
	C784.167053,164.316879 794.911560,177.664444 804.993164,191.482040 
	C821.208191,213.706039 834.056458,237.919952 844.950439,263.135345 
	C850.262085,275.429810 855.834412,287.747375 859.568237,300.556030 
	C865.218262,319.938141 870.047791,339.624481 873.933411,359.435089 
	C876.972961,374.932190 878.038635,390.815582 880.019043,406.522614 
	C880.209351,408.032318 880.666687,409.508331 881.000000,411.000000 
	C881.000000,427.020905 881.000000,443.041779 880.603882,459.716125 
	C878.494690,506.299713 868.386719,550.549683 851.781372,593.177673 
	C835.191772,635.765076 811.960693,674.713318 783.195740,710.308472 
	C756.988281,742.738892 691.234375,799.783447 680.379578,799.467285 
	C692.976562,774.009827 702.167664,747.411804 708.335876,719.598511 
	C713.607178,695.829834 716.008911,671.812683 716.811768,647.633057 
	C717.220703,635.318420 715.859680,622.924561 714.941223,610.593079 
	C714.224487,600.970337 713.612610,591.262695 711.799866,581.814270 
	C707.717896,560.536865 702.636353,539.491455 695.024170,519.113098 
	C687.756348,499.656677 679.101624,480.844910 668.405823,463.092224 
	C658.660767,446.917511 647.719543,431.554688 635.078369,417.366821 
	C621.736206,402.392273 607.534119,388.339020 591.528320,376.383087 
	C573.346069,362.801422 553.791687,351.384064 532.596741,342.843994 
	C508.628998,333.186707 483.863678,326.616791 458.143860,325.282318 
	C441.876648,324.438293 425.359283,325.397766 409.155182,327.279541 
	C395.407227,328.876068 381.830353,332.509186 368.388947,336.044098 
	C350.286133,340.804901 333.590118,349.086304 317.333588,358.248047 
	C298.765442,368.712555 281.631409,381.251709 266.562073,396.224060 
	C254.669556,408.039948 243.584259,420.753235 232.949097,433.730621 
	C215.234451,455.346649 202.086960,479.863220 191.392288,505.513855 
	C181.303696,529.710876 174.195206,554.867737 169.741547,580.859741 
	C166.318573,600.836548 164.604431,620.898621 164.242874,641.022034 
	C163.986801,655.274048 165.532333,669.618774 167.127884,683.826172 
	C168.632553,697.224548 170.547089,710.658997 173.544968,723.789062 
	C178.439148,745.224426 185.296265,766.103943 194.569290,786.120300 
	C196.580627,790.461853 198.362213,794.909790 200.693832,800.343933 
	C178.978790,786.606750 159.613876,771.747498 141.798416,755.072632 
	C123.478569,737.925537 106.242912,719.715027 90.945793,699.661621 
	C75.636864,679.592712 61.913906,658.547729 50.178028,636.290161 
	C38.255177,613.678040 28.551203,590.057617 20.953697,565.580444 
	C11.622974,535.519226 5.211202,504.931427 2.925443,473.504852 
	C2.837537,472.296265 1.666385,471.166504 0.999999,470.000000 
	C1.000000,469.555542 1.000000,469.111115 1.392592,468.153870 
	C1.523455,463.760742 1.261727,459.880371 1.000000,456.000000 
z"
        />
        <path
          fill="#097454"
          opacity="1.000000"
          stroke="none"
          d="
M455.000000,955.000000 
	C445.979095,955.000000 436.958221,955.000000 427.286011,954.605347 
	C422.051880,953.808533 417.391174,953.805969 412.900604,952.930542 
	C398.605011,950.143738 384.195099,947.686096 370.162720,943.873962 
	C354.057617,939.498901 338.983368,932.401794 324.348480,924.211060 
	C306.462158,914.200745 289.954681,902.367065 275.117340,888.383728 
	C265.028168,878.875244 255.219620,868.834106 246.757629,857.892700 
	C235.862503,843.805176 225.471130,829.134521 216.621078,813.706482 
	C204.784271,793.071838 195.656662,771.102600 188.835938,748.149841 
	C181.427414,723.218933 176.453064,697.820801 175.283890,671.990295 
	C174.113144,646.125671 173.684250,620.056946 179.035538,594.430481 
	C182.375183,578.437439 185.508087,562.352783 189.928192,546.645386 
	C196.121613,524.636414 205.343719,503.793518 216.550003,483.793396 
	C227.170761,464.838379 239.381622,447.006927 253.858017,430.892822 
	C262.853577,420.879517 272.935669,411.801788 282.899963,402.709473 
	C295.800995,390.937408 310.284424,381.314392 325.541962,372.839325 
	C356.970520,355.381805 390.742554,345.635681 426.485077,343.137543 
	C444.618378,341.870178 462.861145,342.835876 480.765961,346.139587 
	C506.011444,350.797760 530.065857,359.030426 552.807068,371.227478 
	C573.055176,382.087372 591.678894,395.235443 608.156189,411.027283 
	C620.485535,422.843719 632.048340,435.703796 642.402405,449.281311 
	C658.662842,470.603943 671.418518,494.118286 681.573181,519.030151 
	C695.422424,553.005981 703.728333,588.351257 705.689392,624.845642 
	C706.831848,646.106079 707.024170,667.548950 704.159485,688.895691 
	C701.800232,706.476562 698.722839,723.805969 694.150879,740.965515 
	C685.275208,774.278259 671.408325,805.273560 652.462097,833.998535 
	C639.625427,853.460693 625.324341,871.781372 607.459045,886.950623 
	C596.507080,896.249756 585.458069,905.511414 573.805420,913.889648 
	C558.758057,924.708679 542.123047,932.940735 524.822205,939.488342 
	C506.910156,946.267273 488.416412,951.135986 469.246613,953.032349 
	C464.479492,953.503906 459.747925,954.334473 455.000000,955.000000 
z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
M455.468658,955.000000 
	C459.747925,954.334473 464.479492,953.503906 469.246613,953.032349 
	C488.416412,951.135986 506.910156,946.267273 524.822205,939.488342 
	C542.123047,932.940735 558.758057,924.708679 573.805420,913.889648 
	C585.458069,905.511414 596.507080,896.249756 607.459045,886.950623 
	C625.324341,871.781372 639.625427,853.460693 652.462097,833.998535 
	C671.408325,805.273560 685.275208,774.278259 694.150879,740.965515 
	C698.722839,723.805969 701.800232,706.476562 704.159485,688.895691 
	C707.024170,667.548950 706.831848,646.106079 705.689392,624.845642 
	C703.728333,588.351257 695.422424,553.005981 681.573181,519.030151 
	C671.418518,494.118286 658.662842,470.603943 642.402405,449.281311 
	C632.048340,435.703796 620.485535,422.843719 608.156189,411.027283 
	C591.678894,395.235443 573.055176,382.087372 552.807068,371.227478 
	C530.065857,359.030426 506.011444,350.797760 480.765961,346.139587 
	C462.861145,342.835876 444.618378,341.870178 426.485077,343.137543 
	C390.742554,345.635681 356.970520,355.381805 325.541962,372.839325 
	C310.284424,381.314392 295.800995,390.937408 282.899963,402.709473 
	C272.935669,411.801788 262.853577,420.879517 253.858017,430.892822 
	C239.381622,447.006927 227.170761,464.838379 216.550003,483.793396 
	C205.343719,503.793518 196.121613,524.636414 189.928192,546.645386 
	C185.508087,562.352783 182.375183,578.437439 179.035538,594.430481 
	C173.684250,620.056946 174.113144,646.125671 175.283890,671.990295 
	C176.453064,697.820801 181.427414,723.218933 188.835938,748.149841 
	C195.656662,771.102600 204.784271,793.071838 216.621078,813.706482 
	C225.471130,829.134521 235.862503,843.805176 246.757629,857.892700 
	C255.219620,868.834106 265.028168,878.875244 275.117340,888.383728 
	C289.954681,902.367065 306.462158,914.200745 324.348480,924.211060 
	C338.983368,932.401794 354.057617,939.498901 370.162720,943.873962 
	C384.195099,947.686096 398.605011,950.143738 412.900604,952.930542 
	C417.391174,953.805969 422.051880,953.808533 426.817383,954.605347 
	C285.068207,955.000000 143.136383,955.000000 1.000000,955.000000 
	C1.000000,793.645813 1.000000,632.291565 0.999999,470.468658 
	C1.666385,471.166504 2.837537,472.296265 2.925443,473.504852 
	C5.211202,504.931427 11.622974,535.519226 20.953697,565.580444 
	C28.551203,590.057617 38.255177,613.678040 50.178028,636.290161 
	C61.913906,658.547729 75.636864,679.592712 90.945793,699.661621 
	C106.242912,719.715027 123.478569,737.925537 141.798416,755.072632 
	C159.613876,771.747498 178.978790,786.606750 200.693832,800.343933 
	C198.362213,794.909790 196.580627,790.461853 194.569290,786.120300 
	C185.296265,766.103943 178.439148,745.224426 173.544968,723.789062 
	C170.547089,710.658997 168.632553,697.224548 167.127884,683.826172 
	C165.532333,669.618774 163.986801,655.274048 164.242874,641.022034 
	C164.604431,620.898621 166.318573,600.836548 169.741547,580.859741 
	C174.195206,554.867737 181.303696,529.710876 191.392288,505.513855 
	C202.086960,479.863220 215.234451,455.346649 232.949097,433.730621 
	C243.584259,420.753235 254.669556,408.039948 266.562073,396.224060 
	C281.631409,381.251709 298.765442,368.712555 317.333588,358.248047 
	C333.590118,349.086304 350.286133,340.804901 368.388947,336.044098 
	C381.830353,332.509186 395.407227,328.876068 409.155182,327.279541 
	C425.359283,325.397766 441.876648,324.438293 458.143860,325.282318 
	C483.863678,326.616791 508.628998,333.186707 532.596741,342.843994 
	C553.791687,351.384064 573.346069,362.801422 591.528320,376.383087 
	C607.534119,388.339020 621.736206,402.392273 635.078369,417.366821 
	C647.719543,431.554688 658.660767,446.917511 668.405823,463.092224 
	C679.101624,480.844910 687.756348,499.656677 695.024170,519.113098 
	C702.636353,539.491455 707.717896,560.536865 711.799866,581.814270 
	C713.612610,591.262695 714.224487,600.970337 714.941223,610.593079 
	C715.859680,622.924561 717.220703,635.318420 716.811768,647.633057 
	C716.008911,671.812683 713.607178,695.829834 708.335876,719.598511 
	C702.167664,747.411804 692.976562,774.009827 680.379578,799.467285 
	C691.234375,799.783447 756.988281,742.738892 783.195740,710.308472 
	C811.960693,674.713318 835.191772,635.765076 851.781372,593.177673 
	C868.386719,550.549683 878.494690,506.299713 880.603882,460.184814 
	C881.000000,624.921631 881.000000,789.843201 881.000000,955.000000 
	C739.312500,955.000000 597.624939,955.000000 455.468658,955.000000 
z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
M421.531342,1.000003 
	C420.841675,1.333289 419.702911,1.842238 418.522125,1.973550 
	C400.323517,3.997322 382.067139,5.587484 363.929260,8.051476 
	C338.988800,11.439584 314.924744,18.480427 291.255798,26.932358 
	C264.190979,36.596912 238.422485,48.893005 213.685471,63.568985 
	C194.059845,75.212479 175.339478,88.147369 158.015335,102.837936 
	C142.748047,115.784325 128.163284,129.699432 114.483208,144.315613 
	C93.627853,166.598099 75.699738,191.290451 60.211922,217.600250 
	C46.399963,241.063202 35.123230,265.765259 25.931767,291.442688 
	C18.283173,312.809937 12.428150,334.657135 8.218414,356.888367 
	C5.641846,370.494965 4.703858,384.410645 2.965620,398.179382 
	C2.812233,399.394379 2.180812,400.549072 1.385709,401.865875 
	C1.000000,268.404480 1.000000,134.808960 1.000000,1.000000 
	C141.020828,1.000000 281.041748,1.000000 421.531342,1.000003 
z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
M881.000000,410.531342 
	C880.666687,409.508331 880.209351,408.032318 880.019043,406.522614 
	C878.038635,390.815582 876.972961,374.932190 873.933411,359.435089 
	C870.047791,339.624481 865.218262,319.938141 859.568237,300.556030 
	C855.834412,287.747375 850.262085,275.429810 844.950439,263.135345 
	C834.056458,237.919952 821.208191,213.706039 804.993164,191.482040 
	C794.911560,177.664444 784.167053,164.316879 773.391968,151.024307 
	C767.588318,143.864716 761.556458,136.765289 754.810425,130.521530 
	C742.155457,118.808746 729.285400,107.261215 715.795593,96.537125 
	C703.094177,86.439850 690.027954,76.573486 676.128662,68.268364 
	C658.362427,57.652637 639.898071,48.076157 621.191711,39.195316 
	C597.651001,28.019339 572.978027,19.631094 547.480408,14.072901 
	C530.210510,10.308265 512.845886,6.813420 495.373596,4.201035 
	C483.844269,2.477213 472.048615,2.534276 460.186096,1.396977 
	C600.259399,1.000000 740.518860,1.000000 881.000000,1.000000 
	C881.000000,137.354141 881.000000,273.708405 881.000000,410.531342 
z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
M1.000000,456.460632 
	C1.261727,459.880371 1.523455,463.760742 1.392592,467.820557 
	C1.000000,464.307098 1.000000,460.614166 1.000000,456.460632 
z"
        />
        <path
          fill="#FFFFFF"
          opacity="1.000000"
          stroke="none"
          d="
M1.000000,404.457855 
	C1.259780,407.550018 1.519560,411.100006 1.389670,414.825012 
	C1.000000,411.638580 1.000000,408.277130 1.000000,404.457855 
z"
        />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
