import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import axios from 'src/utils/axios';

import afromileERP from '../../services/repository'; // Import afromileERP separately
import { RepositoryContext } from './repository-context';

// Define repositoryWithAxios outside the component
const repositoryWithAxios = afromileERP(axios);

export const RepositoryProvider = ({ children }) => {
  const [repositories, setRepositories] = useState(null);

  useEffect(() => {
    setRepositories({
      lookupsRepo: repositoryWithAxios('lookups'),
      userRepo: repositoryWithAxios('users'),
      blogRepo: repositoryWithAxios('blogs'),
      roleRepo: repositoryWithAxios('roles'),
      projectRepo: repositoryWithAxios('projects'),
      targetRepo: repositoryWithAxios('targets'),
      taskRepo: repositoryWithAxios('tasks'),
      dataLookupRepo: repositoryWithAxios('data-lookups'),
    });
  }, []); // Empty dependency array to run only on first render

  return <RepositoryContext.Provider value={repositories}>{children}</RepositoryContext.Provider>;
};

RepositoryProvider.propTypes = {
  children: PropTypes.node,
};
