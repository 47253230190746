import { useMemo } from 'react';

import { paths } from 'src/routes/paths';

import { META } from 'src/config-global';
import { useResourcePermitted } from 'src/permissions/acl';

import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  forum: icon('ic_forum'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const usersPermitted = useResourcePermitted('user', 'list');
  const rolesPermitted = useResourcePermitted('role', 'list');
  const projectsPermitted = useResourcePermitted('project', 'list');
  const taskPermitted = useResourcePermitted('task', 'list');
  const blogsPermitted = useResourcePermitted('blog', 'list');
  const insightsPermitted = useResourcePermitted('insight', 'list');

  let authorizedOverviewItems = useMemo(() => [], []);
  let authorizedManagementItems = useMemo(() => [], []);

  const overviewItems = [
    [
      { title: 'Knowledge Base', path: paths.dashboard.root, icon: ICONS.dashboard },
      { title: 'Insights', path: paths.dashboard.analytics.root, icon: ICONS.analytics },
    ],
  ];

  const managementItems = [
    {
      disabled: !usersPermitted,
      title: 'users',
      path: paths.dashboard.users.root,
      icon: ICONS.user,
      // children: [
      //   { title: 'list', path: paths.dashboard.users.root },
      //   // { title: 'create', path: paths.dashboard.users.new },
      // ],
    },
    {
      disabled: !rolesPermitted,
      title: 'roles',
      path: paths.dashboard.roles.root,
      icon: ICONS.lock,
      // children: [
      //   { title: 'list', path: paths.dashboard.roles.root },
      //   { title: 'create', path: paths.dashboard.roles.new },
      // ],
    },
    {
      disabled: !projectsPermitted,
      title: 'projects',
      path: paths.dashboard.projects.root,
      icon: ICONS.job,
      // children: [
      //   { title: 'list', path: paths.dashboard.projects.root },
      //   { title: 'create', path: paths.dashboard.projects.new },
      // ],
    },
    {
      disabled: !taskPermitted,
      icon: ICONS.menuItem,
      title: 'Tracks',
      path: paths.dashboard.tasks.root,
    },
    {
      disabled: !blogsPermitted,
      title: 'Public Forum',
      path: paths.dashboard.forum.posts.root,
      icon: ICONS.forum,
      children: [
        {
          title: 'Blog',
          path: paths.dashboard.forum.posts.root,
        },
      ],
    },
  ];

  authorizedOverviewItems = [];
  overviewItems.forEach((items) => {
    items.forEach((item) => {
      if (item.title === 'Knowledge Base') {
        authorizedOverviewItems.push(item);
      }
      if (item.title === 'Insights' && insightsPermitted) {
        authorizedOverviewItems.push(item);
      }
    });
  });

  authorizedManagementItems = [];
  managementItems.forEach((item) => {
    if (item.title === 'users' && usersPermitted) {
      authorizedManagementItems.push(item);
    }
    if (item.title === 'roles' && rolesPermitted) {
      authorizedManagementItems.push(item);
    }
    if (item.title === 'projects' && projectsPermitted) {
      authorizedManagementItems.push(item);
    }
    if (item.title === 'Public Forum' && blogsPermitted) {
      authorizedManagementItems.push(item);
    }
    if (item.title === 'Tracks' && taskPermitted) {
      authorizedManagementItems.push(item);
    }
  });

  const data = useMemo(
    () => [
      /**
       ** Dashboard|Home
       */
      {
        subheader: `${META.title} v${META.version}`,
        items: authorizedOverviewItems,
      },

      /**
       ** Management
       */
      {
        subheader: 'management',
        items: authorizedManagementItems,
      },
    ],
    [authorizedManagementItems, authorizedOverviewItems]
  );

  return data;
}
