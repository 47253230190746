// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  // * AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  // * DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    analytics: {
      root: `${ROOTS.DASHBOARD}/analytics/overview`,
      taskCompletion: `${ROOTS.DASHBOARD}/analytics/task-completion`,
      targetCompletion: `${ROOTS.DASHBOARD}/analytics/target-completion`,
    },

    users: {
      root: `${ROOTS.DASHBOARD}/users`,
      new: `${ROOTS.DASHBOARD}/users/new`,
      edit: (id) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
    },
    roles: {
      root: `${ROOTS.DASHBOARD}/roles`,
      new: `${ROOTS.DASHBOARD}/roles/new`,
      edit: (id) => `${ROOTS.DASHBOARD}/roles/${id}/edit`,
    },
    projects: {
      root: `${ROOTS.DASHBOARD}/projects`,
      new: `${ROOTS.DASHBOARD}/projects/new`,
      edit: (id) => `${ROOTS.DASHBOARD}/projects/${id}/edit`,
      details: (id) => `${ROOTS.DASHBOARD}/projects/${id}`,

      targets: {
        // root: (id) => `${ROOTS.DASHBOARD}/projects/${id}/targets`,
        new: (id) => `${ROOTS.DASHBOARD}/projects/${id}/targets/new`,
        edit: (projectId, targetId) =>
          `${ROOTS.DASHBOARD}/projects/${projectId}/targets/${targetId}/edit`,
        details: (projectId, targetId) =>
          `${ROOTS.DASHBOARD}/projects/${projectId}/targets/${targetId}`,
      },
    },
    tasks: {
      root: `${ROOTS.DASHBOARD}/forum/tasks`,
      new: `${ROOTS.DASHBOARD}/forum/tasks/new`,
    },
    forum: {
      posts: {
        root: `${ROOTS.DASHBOARD}/posts`,
        new: `${ROOTS.DASHBOARD}/posts/new`,
        edit: (id) => `${ROOTS.DASHBOARD}/posts/${id}/edit`,
        details: (id) => `${ROOTS.DASHBOARD}/posts/${id}`,
      },
    },
  },
};
