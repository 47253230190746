import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

import { PermissionsContext } from './permissions-context';

let setRolesMethod = null;

export function PermissionsProvider({ children }) {
  const [roles, setRoles] = useState(null);

  setRolesMethod = setRoles;

  const value = useMemo(() => ({ roles, setRoles }), [roles]);

  return <PermissionsContext.Provider value={value}>{children}</PermissionsContext.Provider>;
}

export const setRoles = (roles) => {
  if (setRolesMethod) {
    setRolesMethod(roles);
  } else {
    console.error(
      'setUser is not available. Did you forget to wrap your component with UserProvider?'
    );
  }
};

PermissionsProvider.propTypes = {
  children: PropTypes.node,
};
