import { useContext } from 'react';

import { PermissionsContext } from './context';

// ----------------------------------------------------------------------

export const useResourcePermitted = (resource, action) => {
  const { roles } = useContext(PermissionsContext);

  if (!roles) {
    return false;
  }

  // const { roles } = permissions;

  if (!roles) {
    return false;
  }
  for (let i = 0; i < roles.length; i += 1) {
    const role = roles[i];
    const { permissions } = role;

    for (let j = 0; j < permissions.length; j += 1) {
      const permission = permissions[j];

      if (permission.resource === resource && permission.action === action) {
        return true;
      }
    }
  }

  return false;
};

export const usePermitted = () => {
  const usersPermitted = useResourcePermitted('user', 'list');
  const rolesPermitted = useResourcePermitted('role', 'list');
  const projectsPermitted = useResourcePermitted('project', 'list');
  const taskPermitted = useResourcePermitted('task', 'list');
  const blogsPermitted = useResourcePermitted('blog', 'list');
  const insightsPermitted = useResourcePermitted('insight', 'list');

  return {
    checkPermission: (href) => {
      if (href.includes('user') && !usersPermitted) {
        return false;
      }
      if (href.includes('role') && !rolesPermitted) {
        return false;
      }
      if (href.includes('project') && !projectsPermitted) {
        return false;
      }
      if (href.includes('task') && !taskPermitted) {
        return false;
      }
      if (href.includes('post') && !blogsPermitted) {
        return false;
      }
      if (href.includes('analytics') && !insightsPermitted) {
        return false;
      }
      return true;
    },
  };
};
