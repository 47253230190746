import PropTypes from 'prop-types';
import { useMemo, useState, useEffect, useCallback } from 'react';

import axios, { endpoints } from 'src/utils/axios';

import { LookupsContext } from './lookups-context';

let setLookupsMethod = null;

export function LookupsProvider({ children }) {
  const [lookups, setLookups] = useState(null);

  setLookupsMethod = setLookups; // Assign the setUser method to the variable

  const initialize = useCallback(async () => {
    try {
      const response = await axios.get(endpoints.lookups.list);
      const { data } = response.data;
      setLookups(data);
    } catch (error) {
      console.error(error);
    }
  }, [setLookups]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const value = useMemo(() => ({ lookups, setLookups }), [lookups]);

  return <LookupsContext.Provider value={value}>{children}</LookupsContext.Provider>;
}

export const setLookups = (lookups) => {
  if (setLookupsMethod) {
    setLookupsMethod(lookups);
  } else {
    console.error('setLookups is not available.');
  }
};

LookupsProvider.propTypes = {
  children: PropTypes.node,
};
