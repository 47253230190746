import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { usePermitted } from 'src/permissions/acl';

// ----------------------------------------------------------------------

function RouterLink({ href, ...other }) {
  const { checkPermission } = usePermitted();
  const permitted = checkPermission(href);
  const handleClick = useCallback(() => {
    if (!permitted) {
      <Link to={href} onClick={handleClick} {...other} />;
    }
  }, [href, permitted, other]);

  return <Link to={href} onClick={handleClick} {...other} />;
}

RouterLink.propTypes = {
  href: PropTypes.string,
};

export default RouterLink;
