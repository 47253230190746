import { paths } from 'src/routes/paths';

import axios, { endpoints } from 'src/utils/axios';

import { setRoles } from 'src/permissions/context/permissions-provider';

// ----------------------------------------------------------------------

function jwtDecode(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

export const tokenExpired = (exp, refreshToken) => {
  function handleVisibilityChange() {
    console.log('document.hidden:', document.hidden);
    if (document.hidden) {
      clearTimeout(expiredTimer);
    } else {
      const currentTime = Date.now();
      const timeLeft = exp * 1000 - currentTime - 20000; // 20s

      expiredTimer = setTimeout(() => {
        refresh(refreshToken);
      }, timeLeft);
    }
  }

  document.addEventListener('visibilitychange', handleVisibilityChange);
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime - 20000; // 20s

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    refresh(refreshToken);

    // sessionStorage.removeItem('accessToken');

    // window.location.href = paths.auth.jwt.login;
  }, timeLeft);
};

// ----------------------------------------------------------------------

export const refresh = async (token) => {
  const data = {
    refreshToken: token,
  };

  const response = await axios.post(endpoints.auth.refresh, data);

  if (response.status === 200) {
    const { accessToken, refreshToken } = response.data.data;
    const decoded = jwtDecode(accessToken);
    setRoles(decoded.roles);
    setSession(accessToken, refreshToken);
  } else {
    setSession(null);
  }
};

// ----------------------------------------------------------------------

export const setSession = (accessToken, refreshToken) => {
  if (accessToken && refreshToken) {
    sessionStorage.setItem('accessToken', accessToken);
    sessionStorage.setItem('refreshToken', refreshToken);

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    const { exp } = jwtDecode(accessToken);
    tokenExpired(exp, refreshToken);
  } else {
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('refreshToken');

    delete axios.defaults.headers.common.Authorization;
    // go to login page
    window.location.href = paths.auth.jwt.login;
  }
};

// ----------------------------------------------------------------------

//
